import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { AboutHeader, BannerLeft, BannerButton } from "../utils"
// import ReservationForm from "../components/globals/ReservationForm"

import img from "../images/bcg/christmas-at-traditions.jpg"

const ChristmasPage = () => (
  <Layout>
    <SEO title="Christmas at Traditions" keywords={[`Christmas in Clemson, Seneca, and greater Upstate SC`]} />
    <AboutHeader img={img}>
      <BannerLeft title="Christmas Traditions" subtitle="Merry Christmas!">
        <hr />
        <p>
          From all of us here at Traditions on the Lake, we wish you a Merry Christmas! 
        </p>
      </BannerLeft>
    </AboutHeader>

    <section id="special">
      <div className="row px-0 py-5 justify-content-center">
        <div className="col-12 col-md-10 col-lg-9 px-0 py-4 mx-0">
          <h2>Closed Chirstmas Day</h2>
          <br />
          <br />
          <p>
           We are open Christmas Eve 2021 (regular hours) and closed on Christmas Day! Stay on the nice list y'all!
          </p>
        </div>
      </div>
      <h2>Call Us</h2>
      <div className="row px-0 justify-content-center catering-form" >
        <BannerButton>
          <a href="tel:864-722-2800" rel="noopener noreferrer">864-722-2800</a>
        </BannerButton>
      </div>
      <br />
      <br />
      <br />
    </section>
  </Layout>
)

export default ChristmasPage
